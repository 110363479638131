












import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DialogResearchComplete extends Vue {
  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public submit() {
    this.$emit('submit');
  }
}
