

























































































import { Research, Respondent as RespondentModel } from '@app/models';
import Vue from 'vue';
import { Component, Watch, Ref } from 'vue-property-decorator';
import { Route } from 'vue-router';
import _ from 'lodash';

import { NavGuard } from '../utils/decorators';
import {
  respondent,
  RespondentModule,
} from '../store/modules/respondent-module';
import { user } from '../store/modules/user-module';
import { ModuleModule } from '../store/modules/module-module';
import { StorageModule } from '../store/modules/storage-module';
import { ResearchModule } from '../store/modules/research-module';
import {
  BlockElement,
  Module,
  ModuleFlow,
  FlowTree,
} from '@bcase/module-editor';

import { getModule } from 'vuex-module-decorators';
import fb from 'firebase/app';

import DialogResearchComplete from '../components/3-dialogs/dialog-research-complete.vue';
import { getPercentage } from '../utils/data-manipulation';

@Component({
  components: {
    DialogResearchComplete,
  },
})
export default class Respondent extends Vue {
  private modules: ModuleModule = getModule(ModuleModule);
  private storage: StorageModule = getModule(StorageModule);
  private research: Research | null = null;
  private researchModules: any = [];

  public respondent: RespondentModule = respondent;

  public growButton: boolean = false;
  public dialog: boolean = false;
  public progressStack: any[] = [];

  public loading: boolean = false;

  public transitionName: string = 'slide-left';

  public get respondentData(): RespondentModel {
    return this.respondent.respondentData!;
  }

  public get introduction() {
    if (!this.research) return '';

    const { introduction } = this.research;
    const text = typeof introduction === 'string' ? introduction : '';

    const start = this.research.date.start!.toDate().toLocaleDateString();
    const end = this.research.date.end!.toDate().toLocaleDateString();

    return text
      .replace(/{company:name}/g, this.$company.name || '')
      .replace(/{research:title}/g, this.research.title)
      .replace(/{research:start}/g, start)
      .replace(/{research:end}/g, end)
      .replace(/{research:response}/g, (this.research.goal || 0) + '%')
      .replace(/{user:([^}]*)}/g, (_, prop) => {
        const data = this.respondentData;
        if (!data) return '';

        switch (prop) {
          case 'email':
            return data.email || '';
          case 'firstName':
            return data.first_name || '';
          case 'lastName':
            return data.name || '';
          default:
            return (data as any)[prop];
        }
      });
  }

  public get formuleSpecificQuestions() {
    return (this.research && (this.research.questions as BlockElement[])) || [];
  }

  public get productServices() {
    if (!this.research) return [];
    const products = this.research.products || [];
    const services = this.research.services || [];
    return [...products, ...services];
  }

  public get productServicesAnswer() {
    if (!this.research) return [];
    return this.respondent.resultBydashboard('products-services-1').answer
      .value;
  }

  public get coreValues() {
    if (!this.research) return [];
    return this.research['core-value'] || [];
  }

  public getElements(module: Module) {
    if (module.metadata.fsq) {
      return (
        (this.research && (this.research.questions as BlockElement[])) || []
      );
    }

    if (this.isProductServicesEl) {
      return this.productServicesAnswer.map((v: any, i: number) => {
        const id = this.isProductServicesEl!.id + i;
        const rawQuestion = (this.isProductServicesEl as any)!.question;
        const question = rawQuestion.replace(/{productenDiensten}/g, v.text);
        return { ...this.isProductServicesEl, id, question };
      });
    }

    // if (this.isCoreValue) {
    //   return this.coreValues.map((v, i) => {
    //     const id = this.isCoreValue!.id + i;
    //     const rawQuestion = (this.isCoreValue as any)!.question;
    //     const question = rawQuestion.replace(/{kernwaarde}/g, v);
    //     return { ...this.isCoreValue, id, question };
    //   });
    // }

    return this.elements;
  }

  public get moduleBlockIndex() {
    return parseInt(this.currentModule + ',' + this.progression);
  }

  public get currentModule() {
    return parseInt(this.$route.params.progress, 10) || 0;
  }

  public get currentResearchModule() {
    return this.researchModules[this.currentModule];
  }

  public get idCustomElement() {
    const id = [
      this.currentResearchModule.metadata.core_values,
      this.currentResearchModule.metadata.product_service,
    ];
    return id;
  }

  public get customElements() {
    const elements = this.elements || [];
    const search = 'products-services-2' || 'core-value-2';

    return elements.find(el => {
      return (
        (el.metadata.dashboard as any) === 'products-services-2' ||
        'core-value-2'
      );
    });
  }

  public get isProductServicesEl() {
    const elements = this.elements || [];

    return elements.find(el => {
      return (el.metadata.dashboard as any) === 'products-services-2';
    });
  }

  public get isCoreValue() {
    const elements = this.elements || [];

    return elements.find(el => {
      return (el.metadata.dashboard as any) === 'core-value-2';
    });
  }

  public get nextModule() {
    return this.researchModules[this.currentModule + 1];
  }

  public get getNextBlock() {
    const nested = this.modules.blocks.map(b => b.elements.map(e => e.id));
    const ids = ([] as string[]).concat(...nested);

    // Gather element ids
    // const ids = this.modules.block
    //   ? this.modules.block.elements.map(e => e.id)
    //   : [];

    // Gather element answers
    const answers = ids.reduce((acc, id) => {
      const result = this.respondent.resultById(id);
      if (result) acc[id] = result.answer.value;
      return acc;
    }, {} as any);

    return this.modules.getNextBlock(answers);
  }

  public get elements() {
    return this.modules.block && this.modules.block.elements;
  }

  public get moduleBlock() {
    return this.$route.params.block;
  }

  public get params() {
    if (
      this.$route.name === 'respondent' ||
      !this.$route.params.module ||
      !this.$route.params.block
    )
      return undefined;
    const [mid, mversion] = this.$route.params.module.split('@');
    const [bid, bversion] = this.$route.params.block.split('@');

    return {
      module: { id: mid, version: mversion },
      block: { id: bid, version: bversion },
    };
  }

  public get prevParams() {
    if (!this.progressStack.length) return null;
    const prev = this.progressStack[this.progressStack.length - 1];
    const [mid, mversion] = prev.module.split('@');
    const [bid, bversion] = prev.block.split('@');

    return {
      module: { id: mid, version: mversion },
      block: { id: bid, version: bversion },
    };
  }

  public get startModuleBlock() {
    const current = this.currentResearchModule;
    const { id: mid, version: mversion } = current;
    const { id: bid, version: bversion } = current.flow.start;
    return {
      module: { id: mid, version: mversion },
      block: { id: bid, version: bversion },
    };
  }

  public get getArrayBlocks() {
    const current = this.$route.params.block;

    return this.researchModules.reduce((acc: string[], module: Module) => {
      // Skip formula specific questions if not provided
      if (module.metadata.fsq && !this.research!.questions.length) return acc;

      const tree = new FlowTree(module.flow);
      const node = tree.getNode(current) || tree.root;
      return [...acc, ...node.deepestPath.map(n => n.id)];
    }, []);
  }

  // public get getArrayBlocks() {
  //   const current = this.$route.params.block;
  //   const count = 6;

  //   return this.researchModules.reduce((acc: string[], module: Module) => {
  //     const tree = new FlowTree(module.flow);
  //     const node = tree.getNode(current) || tree.root;

  //     const path = node.deepestPath.map(n => n.id);

  //     // const idProductService = module.metadata.product_service;
  //     // const idCoreValues = module.metadata.core_values;

  //     // const id = idCoreValues || idProductService;
  //     // const index = id ? path.indexOf(id) : -1;
  //     // console.log('ID', idCoreValues, idProductService);
  //     // console.log('INDEX', index);
  //     // console.log(id);
  //     // console.log('path1:', path);
  //     // console.log('index path', path.indexOf(id));

  //     // if (index >= 0) {
  //     //   const copyBlock = module.flow.blocks[id];

  //     //   const next = Array(count)
  //     //     .fill(null)
  //     //     .map((_, i) => {
  //     //       const mockId = id + '@C' + i;
  //     //       // (module.flow.blocks as any)[mockId] = copyBlock;
  //     //       return mockId;
  //     //     });

  //     //   path.splice(index, 1, ...next);
  //     // }

  //     return [...acc, ...path];
  //   }, []);
  // }

  public get progression() {
    return (
      this.getArrayBlocks.indexOf(
        (this.params && this.params.block.id) ||
          (this.prevParams && this.prevParams.block.id)
      ) + 1
    );
  }

  public get fsq() {
    return !!(
      this.researchModules[this.$route.params.progress].metadata.fsq &&
      (this.formuleSpecificQuestions && this.formuleSpecificQuestions.length) >
        0
    );
  }

  // public beforeUpdate() {
  //   console.log('modules: ', this.modules);
  //   console.log('currentModule: ', this.currentModule);
  //   console.log('researchModules: ', this.researchModules);
  //   console.log('respondent: ', this.respondent);
  //   console.log('research: ', this.research);
  // }

  public async created() {
    await respondent.ready;
    if (respondent.initialize) {
      this.init();
    }
  }

  public mounted() {
    window.addEventListener('keyup', event => {
      if (event.keyCode === 39) {
        this.next();
      } else if (event.keyCode === 37) {
        this.prevBlock();
      }
    });
  }

  @Watch('$route')
  public async routeChange() {
    if (respondent.done) return this.researchComplete();
    await this.modules.load(this.params);
    this.growButton = false;

    if (!this.currentResearchModule) return;
    this.storage.bind(this.currentResearchModule.id);
  }

  @Watch('respondent.initialize', { deep: true })
  public async init() {
    if (!respondent.initialize) return;

    // if (this.respondent.color) {
    //   this.onColor(this.respondent.color);
    // }

    this.modules.bind();

    this.research = await this.getResearch(respondent.rid);
    this.researchModules = this.getResearchModules();

    await this.modules.load(this.params);

    this.progressStack = this.respondentData['progress-stack'] || [];
  }

  public swipeAllowed(e: Event) {
    const target = e.target as any;
    const name = target.nodeName.toLowerCase();

    return (
      name !== 'input' && ('type' in target ? target.type !== 'slider' : true)
    );
  }

  public swipeHandler(e: Event) {
    if (!this.swipeAllowed(e)) return;

    const direction = e.type;

    if (direction == 'swipeleft') {
      this.next();
    } else if (direction == 'swiperight') {
      this.prevBlock();
    }
  }

  public async getResearch(researchId: string) {
    const researchRef = this.$firebase.col('research').doc(researchId);
    return await researchRef
      .get()
      .then(doc => (doc.exists ? (doc.data() as Research) : null));
  }

  public getResearchModules() {
    if (!this.research) return [];

    const modules = this.modules.active.filter((module: Module) => {
      const moduleTarget = module.category.split('+');
      if (moduleTarget.indexOf(this.respondent.target) < 0) return false;

      if (!this.research!.structure) return true;
      return this.research!.structure.module.indexOf(module.id) >= 0;
    });

    return modules.sort((a, b) => a.metadata.order - b.metadata.order);
  }

  public handleStart() {
    const research = this.research && this.research.id;
    const respondent = this.respondent && this.respondent.id;

    const startTime = fb.firestore.Timestamp.fromDate(new Date());

    const callback = () => {
      this.modules.load(this.params);
      this.$firebase
        .doc(`research/${research}/respondent/${respondent}`)
        .update({
          status: 'started',
          time_started: startTime,
          'progress-stack': [this.$route.params],
        });
    };

    this.$router.push(
      {
        name: 'complete-research',
        params: {
          module: this.startModuleBlock.module.id + '@v1',
          block: this.startModuleBlock.block.id + '@v1',
          progress: '0',
        },
      },
      callback
    );
  }

  public handleContinue() {
    this.progressStack =
      this.respondentData && this.respondentData['progress-stack'];

    if (!this.progressStack || this.progressStack.length < 1) {
      return this.handleStart();
    }

    const callback = () => {
      this.modules.load(this.params);
      this.progressStack.pop();
    };

    const { block, module, progress } =
      this.progressStack[this.progressStack.length - 1];

    this.$router.push(
      {
        name: 'complete-research',
        params: { module, block, progress },
      },
      callback
    );
  }

  public async prevBlock() {
    this.transitionName = 'slide-right';

    if (this.progressStack.length < 1) {
      this.$router.push({ name: 'respondent' });
    } else {
      const prev = this.progressStack[this.progressStack.length - 1];
      const [mid, mversion] = prev.module.split('@');
      const [bid, bversion] = prev.block.split('@');

      const params = {
        module: { id: mid, version: mversion },
        block: { id: bid, version: bversion },
      };

      const callback = () => {
        this.progressStack.pop();
        this.saveProgress(this.progressStack);
        this.modules.load(params);
      };

      this.$router.replace(
        {
          name: 'complete-research',
          params: {
            module: params.module.id + '@v1',
            block: params.block.id + '@v1',
            progress: prev.progress as any,
          },
        },
        callback
      );
    }
  }

  public nextBlock() {
    const callback = () => {
      this.modules.load(this.params);
    };

    // // Gather element ids
    // const ids = this.modules.block
    //   ? this.modules.block.elements.map(e => e.id)
    //   : [];

    // // Gather element answers
    // const answers = ids.reduce((acc, id) => {
    //   const result = this.respondent.resultById(id);
    //   if (result) acc[id] = result.answer.value;
    //   return acc;
    // }, {} as any);

    const next = this.getNextBlock;

    // if (this.customElements && this.) {
    //   console.log('has custom');
    //   return this.$router.push(
    //     {
    //       name: 'complete-research',
    //       params: {
    //         module: this.currentResearchModule.id + '@v1',
    //         block: this.$route.params.block,
    //         progress: this.$route.params.progress as any,
    //         c: this.$route.params.c + 1
    //       }
    //     },
    //     callback
    //   );
    // }
    if (next) {
      this.$router.push(
        {
          name: 'complete-research',
          params: {
            module: this.currentResearchModule.id + '@v1',
            block: next.id + '@v1',
            progress: this.$route.params.progress as any,
          },
        },
        callback
      );
    } else {
      if (!this.nextModule) return;

      let progress = this.currentModule + 1;
      let module = this.nextModule;

      // Skip formula specific questions if not provided
      if (this.nextModule.metadata.fsq && !this.research!.questions.length) {
        progress = this.currentModule + 2;
        module = this.researchModules[this.currentModule + 2];
      }

      this.$router.push(
        {
          name: 'complete-research',
          params: {
            module: module.id + '@v1',
            block: module.flow.start.id + '@v1',
            progress: '' + progress,
          },
        },
        callback
      );
    }
    this.progressStack.push(this.$route.params);
    this.saveProgress(this.progressStack);
  }

  public blockDone() {
    setTimeout(() => this.next(), 333);
  }

  public discardDialog() {
    this.dialog = false;
  }

  public submitDialog() {
    this.dialog = false;
  }

  public async saveProgress(progress: any[]) {
    const research = this.research && this.research.id;
    const respondent = this.respondent && this.respondent.id;

    const percentage = getPercentage(
      this.getArrayBlocks.length,
      progress.length + 1
    );

    await this.$firebase
      .doc(`research/${research}/respondent/${respondent}`)
      .update({ 'progress-stack': progress, progress: percentage });
  }

  public async openDialog() {
    if (!(await this.next())) return;
    this.dialog = true;
  }

  public async onSubmit() {
    const research = this.research && this.research.id;
    const respondent = this.respondent && this.respondent.id;
    const startTime = this.respondentData && this.respondentData.time_started;
    const endTime = fb.firestore.Timestamp.fromDate(new Date());

    console.log(research, respondent, startTime, endTime);
    if (!startTime || !endTime) return;

    const durationInMin = (endTime.seconds - startTime.seconds) / 60;
    this.$firebase.doc(`research/${research}/respondent/${respondent}`).update({
      status: 'done',
      time_ended: endTime,
      total_time: durationInMin,
    });

    this.researchComplete();
  }

  public async next() {
    const form = this.$el.querySelector('bce-form');
    if (!form) return false;

    this.transitionName = 'slide-left';

    const elements = Array.from(this.$el.querySelectorAll('bce-element'));
    const elErrors = await Promise.all(elements.map(el => el.validate()));
    const errors = ([] as typeof elErrors[number]).concat(...elErrors);
    if (!errors.length) {
      await this.checkLocks(elements);
      this.nextBlock();
      return true;
    }

    const messages = errors.map(e => ` ${this.$t('validation-' + e.rule)}`);
    const message = messages.slice(0, 2).join('\n\n');
    this.$bce.message(message);
    return false;
  }

  public async checkLocks(elements: HTMLBceElementElement[]) {
    const batch = this.$firebase.batch();
    const templates = elements.map(el => el.template);

    // Lock core-value-1
    const core1 = templates.find(t => {
      return t.metadata && t.metadata.dashboard === 'core-value-1';
    });

    if (core1) {
      const resultId = this.getId(core1.id);
      if (resultId)
        batch.update(this.$firebase.doc(`results/${resultId}`), { lock: true });
    }

    await batch.commit();
  }

  private onColor(color?: string) {
    if (!color) return;

    const root = this.$root.$el as HTMLBceRootElement;
    root.accent('primary', color);
  }

  public async researchComplete() {
    await this.$nextTick();
    this.$bce.alert(
      '',
      'Het onderzoek is afgerond, wij danken u voor uw medewerking!'
    );
    await respondent.signOut();
    this.$router.push('/');
  }

  public getId(elementId: string) {
    const idExists = this.respondent.results.find((result: any) => {
      if (
        this.respondent.sub === result.sub &&
        elementId === result.question.id
      ) {
        return result.id;
      }
    });
    return idExists ? idExists.id : this.$firebase.generateId();
  }

  @NavGuard
  public async beforeRouteEnter(to: Route, from: Route, next: Function) {
    await respondent.ready;

    const token = to.query.token as string;
    if (!token) return next();

    // Already logged in
    const { uid } = JSON.parse(atob(token.split('.')[1]));
    if (respondent.id === uid) return next();
    await respondent.signOut();

    // Incorrect URL
    if (Array.isArray(token) || !token) return next('/404');

    next();
    await respondent.signIn({ token });
    next('/respondent');
  }
}
