import Vue from 'vue';
import { createDecorator } from 'vue-class-component';

type MethodDecorator = <T>(
  target: Vue,
  key: string,
  descriptor: TypedPropertyDescriptor<T>
) => TypedPropertyDescriptor<T> | void;

export const NavGuard: MethodDecorator = (target, key) => {
  const GUARDS = ['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'];
  if (GUARDS.indexOf(key) < 0) {
    const names = GUARDS.map(n => `"${n}"`).join(', ');
    const message =
      'The NavGuard decorator can only be used on a method one of the following names: ';
    throw new Error(message + names);
  }

  createDecorator((options: any) => {
    options[key] = (target as any)[key];
  })(target, key);
};
